.loginContainer {
    height: 100vh;
}

.loginLeftColumn {
    -webkit-box-shadow: 8px 0px 11px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 8px 0px 11px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 8px 0px 11px 0px rgba(0, 0, 0, 0.05);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px !important;
}

.loginRightColumn {
    background-image: url('../img/login-bg.jpg');
    background-size: cover;
    background-position: center center;
}

#zalogujGoogle {
    margin-top: 50px;
    width: 50%;
    text-align: center;
    cursor: pointer;
}

.logoCarView {
    margin-top: 50px;
}